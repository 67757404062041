














import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  data: () => ({

  }),

  components: {
    HomeAppBar: () => import('@/layouts/AppBar.vue'),
    HomeFooter: () => import('@/layouts/Footer.vue')
  },
});
