










import Vue from 'vue'
import ImageCropper from '../components/ImageCropper.vue'

export default Vue.extend({
  name: 'Home',

  data: () => ({

  }),

  components: {
    ImageCropper,
    Settings: () => import('@/layouts/Settings.vue'),
  }
})
