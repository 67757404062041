































































































  import Vue from 'vue'
  import { Cropper } from 'vue-advanced-cropper'
  import { EventBus } from '@/event-bus'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  import 'vue-advanced-cropper/dist/style.css'
  import 'vue-advanced-cropper/dist/theme.compact.css'
import { VAlert } from 'vuetify/lib'

  function getMimeType(file: any, fallback = '') {
    const byteArray = (new Uint8Array(file)).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
    switch (header) {
          case "89504e47":
              return "image/png";
          case "47494638":
              return "image/gif";
          case "ffd8ffe0":
          case "ffd8ffe1":
          case "ffd8ffe2":
          case "ffd8ffe3":
          case "ffd8ffe8":
              return "image/jpeg";
          default:
              return fallback;
      }
  }

  // https://github.com/Norserium/vue-advanced-cropper

  export default Vue.extend({
    name: 'ImageCropper',

    components: {
      Cropper,
      vueDropzone: vue2Dropzone
    },

    data: () => ({
      img: 'https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg',
      resizeImage: true,
      resultImage: null,
      width: 1920,
      height: 1080,
      predefinedWidth: 1920,
      predefinedHeight: 1080,
      image: { name: '', src: '', type: '' },
      dropzoneOptions: {
        url: '/',
        thumbnailWidth: 150,
        dictDefaultMessage: "Bild hier herziehen",
        includeStyling: false,
        previewsContainer: false,
        uploadMultiple: false,
        acceptedFiles: 'image/jpg,image/jpeg,image/png',
        autoProcessQueue: false
      },
      selectItems: ['Vorgaben', 'Instagram', 'Facebook'],
      selectedItem: 'Vorgaben',
      selectableResolutions: [] as any,
      predefinedResolutions: {
        customPreset: [{
          thumb: {
            height: 80,
            width: 45,
            name: '9:16'
          },
          name: 'Vorgabe 1',
          resolution: {
            width: 1080,
            height: 1920
          }
        }],
        instagram: [{
          thumb: {
            height: 80,
            width: 45,
            name: '9:16'
          },
          name: 'Story',
          resolution: {
            width: 1080,
            height: 1920
          }
        }, {
          thumb: {
            height: 80,
            width: 80,
            name: '1:1'
          },
          name: 'Quadrat',
          resolution: {
            width: 1080,
            height: 1080
          }
        }, {
          thumb: {
            height: 80,
            width: 64,
            name: '4:5'
          },
          name: 'Hochformat',
          resolution: {
            width: 1080,
            height: 1350
          }
        }, {
          thumb: {
            height: 42,
            width: 80,
            name: '1.9:1'
          },
          name: 'Querformat',
          resolution: {
            width: 1080,
            height: 566
          }
        }],
        facebook: [{
          thumb: {
            height: 42,
            width: 80,
            name: '1.9:1'
          },
          name: 'Post',
          resolution: {
            width: 1200,
            height: 628
          }
        }]
      }
    }),

    created () {
      const _this = this as any
      EventBus.$on('set-width', (val) => {
        _this.width = val
        _this.predefinedWidth = val
        _this.recalculateCustomResolutions()
      })

      EventBus.$on('set-height', (val) => {
        _this.height = val
        _this.predefinedHeight = val
        _this.recalculateCustomResolutions()
      })

      this.selectableResolutions = this.predefinedResolutions.customPreset
    },

    mounted() {
      (this as any).recalculateCustomResolutions()
    },

    methods: {
      recalculateCustomResolutions () {
        const x = this.predefinedHeight
        const y = this.predefinedWidth

        let h = 0
        let w = 0
        let ratio = ''

        if (x > y) {
          h = 80
          w = Math.round((80 / this.predefinedHeight * this.predefinedWidth) * 10) / 10

          const wRation = Math.round(this.predefinedWidth / this.predefinedHeight * 100) / 100
          ratio = `${wRation}:1`
        } else {
          h = Math.round((80 / this.predefinedWidth * this.predefinedHeight) * 10) / 10
          w = 80

          const wRation = Math.round(this.predefinedHeight / this.predefinedWidth * 100) / 100
          ratio = `1:${wRation}`
        }

        this.predefinedResolutions.customPreset = [{
          thumb: {
            height: h,
            width: w,
            name: ratio
          },
          name: 'Vorgabe 1',
          resolution: {
            width: this.predefinedWidth,
            height: this.predefinedHeight
          }
        }]

        if (this.selectedItem === 'Vorgaben') {
          this.selectableResolutions = this.predefinedResolutions.customPreset
        }
      },
      getResolutionStyle (resolution) {
        return `width: ${resolution.thumb.width}px; height: ${resolution.thumb.height}px;`
      },
      resolutionGroupSelected (val) {
        if (val === 'Vorgaben') {
          this.selectableResolutions = this.predefinedResolutions.customPreset
        } else if (val === 'Instagram') {
          this.selectableResolutions = this.predefinedResolutions.instagram
        } else if (val === 'Facebook') {
          this.selectableResolutions = this.predefinedResolutions.facebook
        }
      },
      resolutionSelected (resolution) {
        this.width = resolution.resolution.width
        this.height = resolution.resolution.height
      },
      fileAdded(file) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        const blob = URL.createObjectURL(file)

        const reader = new FileReader()
        reader.onload = (e) => {
          if (!e) return
          this.image = {
            name: file.name,
            src: blob,
            type: e.target ? getMimeType(e.target.result) : '',
          }
        }
        reader.readAsArrayBuffer(file)
      },
      crop() {
        if (!this.$refs.cropper) return
        const cropper = this.$refs.cropper as any

        const { coordinates, canvas, } = cropper.getResult()
        const self = this
        
        canvas.toBlob( (blob: any) => {
          this.resultImage = blob

          const windowNavigator = window.navigator as any
          if (typeof windowNavigator.msSaveBlob !== 'undefined') {
            windowNavigator.msSaveBlob(blob, self.image.name)
            return
          }
          const blobURL = window.URL.createObjectURL(blob)
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', self.image.name)
          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
          }
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobURL)
          }, 100)
        }, 'image/jpeg', 0.7)
      },
      resetImage() {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
          this.image.src = ''
        }
      }
    },
    computed: {
      stencilProps: function() {
        return {
          aspectRatio: this.width / this.height
        }
      },
      canvasProps: function() {
        return {
          width: this.width,
          height: this.height
        }
      }
    }
  })
